<template>
    <div class="spinner-container" v-if="isViewerLoading">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <div class="row bg-light pb-2 pt-2">
        <template v-for="attribute in attributes" :key="attribute.Field">
            <div v-if="(props.dataObject.current.hasOwnProperty(attribute.Field) && !hiddenFields.includes(attribute.Field)) && (attribute.Required || attribute.IsSet)" class="form-group col-sm-4 col-6" :class="props.customClass"> 
                <label :title="attribute.Title?.value ?? attribute.Title">{{attribute.Label?.value ?? attribute.Label}}</label>
                <ODataLookup v-if="attribute.InputType == 'ODataLookup'" :disabled="(disabledDO || attribute.Disabled) && attribute.Field != 'ObsoleteReason'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" v-model="props.dataObject.current[attribute.Field]" class="form-control" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"/>
                <OPersonsLookup v-if="attribute.InputType == 'OPersonsLookup'" :disabled="(disabledDO || attribute.Disabled) && attribute.Field != 'ObsoleteReason'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" v-model="props.dataObject.current[attribute.Field]" class="form-control" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"/>
                <input v-if="attribute.InputType == 'input'" type="text" :disabled="disabledDO || attribute.Disabled" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}">
                <OTextArea v-if="attribute.InputType == 'OTextArea'" :disabled="disabledDO || attribute.Disabled" @blur="saveDataObject" :renderHTML="attribute.RenderHTML" rows="attribute.Rows" class="form-control" v-model="props.dataObject.current[attribute.Field]" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"> </OTextArea>
                <o-date-picker v-if="attribute.InputType == 'o-date-picker'" :disabled="(disabledDO || attribute.Disabled) && attribute.Field != 'Obsolete'" :callback="saveDataObject" v-model="props.dataObject.current[attribute.Field]" format="Short Date" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}" class="form-control"></o-date-picker>
                <ODataLookup v-if="attribute.InputType == 'ODataLookupCheckBoxes'" :bind="sel => setCheckBoxValues(sel, attribute.Field, attribute.Columns[0].field)" 
                                        :itemLoaded="lkpItem => checkBoxesIsSelected(lkpItem, props.dataObject.current[attribute.Field], attribute.Columns[0].field)" :disabled="disabledDO || attribute.Disabled" 
                                        :value="getCheckboxesJsonValue(props.dataObject.current[attribute.Field])" class="form-control" :data-object="attribute.DataObject" :columns="attribute.Columns" 
                                        :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" 
                                        :class="{'border border-danger':attribute.Required  && !props.dataObject.current[attribute.Field]}"/>

                <div class="d-flex" v-if="attribute.InputType == 'input2x'">
                    <ONumberEditor  type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control w-25" :disabled="disabledDO || attribute.Disabled" format="%"/>
                    <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field2]" class="form-control w-75 ms-1" :disabled="disabledDO || attribute.Disabled">
                </div>
            </div>
        </template>
        </div>
        <div class="row bg-light mt-3">
        <div class="form-group col-12 pt-2">
            <input type="text" class="form-control" placeholder="Search For Attributes..." v-model="searchForAttributes">
        </div>

        <template v-for="(group, index) in groupedAttributes" :key="index">
            <div class="accordion mt-2 pb-3" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" :id="'heading' + index">
                        <button class="accordion-button border" type="button" data-bs-toggle="collapse" :class="{'collapsed': (group.collapsed && !searchForAttributes) || (searchForAttributes && !group.attributes.find((attr) => attr.Label.toLowerCase().includes(searchForAttributes.toLowerCase())))}" :data-bs-target="'#collapse' + index" :aria-expanded="((!group.collapsed && !searchForAttributes) || (searchForAttributes && group.attributes.find((attr) => attr.Label.toLowerCase().includes(searchForAttributes.toLowerCase())))) ? 'true' : 'false'" :aria-controls="'collapse' + index" style="z-index: auto !important;">
                            {{ group.name }}
                        </button>
                    </h2>
                    <div :id="'collapse' + index" class="accordion-collapse collapse bg-light" :class="{'show': (!group.collapsed && !searchForAttributes) || (searchForAttributes && group.attributes.find((attr) => attr.Label.toLowerCase().includes(searchForAttributes.toLowerCase())))}" :aria-labelledby="'heading' + index" data-bs-parent="#accordionExample">
                        <div class="accordion-body row pt-2">
                            <template v-for="attribute in group.attributes" :key="attribute.Field">
                                <div v-if="props.dataObject.current.hasOwnProperty(attribute.Field) && !hiddenFields.includes(attribute.Field) && !attribute.Required && !attribute.IsSet && (attribute.Label.toLowerCase().includes(searchForAttributes.toLowerCase()) || !searchForAttributes)" class="form-group col-sm-4 col-6 p-1" :class="props.customClass"> 
                                    <label :title="attribute.Title?.value ?? attribute.Title">{{attribute.Label?.value ?? attribute.Label}}</label>
                                    <ODataLookup v-if="attribute.InputType == 'ODataLookup'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" :disabled="disabledDO || attribute.Disabled" v-model="props.dataObject.current[attribute.Field]" class="form-control" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required  && !props.dataObject.current[attribute.Field]}"/>
                                    <OPersonsLookup v-if="attribute.InputType == 'OPersonsLookup'" :disabled="(disabledDO || attribute.Disabled) && attribute.Field != 'ObsoleteReason'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" v-model="props.dataObject.current[attribute.Field]" class="form-control" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"/>
                                    <input v-if="attribute.InputType == 'input'" type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control" :disabled="disabledDO || attribute.Disabled" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}">
                                    <OTextArea v-if="attribute.InputType == 'OTextArea'" @blur="saveDataObject" :renderHTML="attribute.RenderHTML" :disabled="disabledDO || attribute.Disabled" rows="attribute.Rows" class="form-control" v-model="props.dataObject.current[attribute.Field]" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"> </OTextArea>
                                    <o-date-picker v-if="attribute.InputType == 'o-date-picker'" class="form-control" :callback="saveDataObject" v-model="props.dataObject.current[attribute.Field]" :disabled="disabledDO || attribute.Disabled" format="Short Date" :class="{'form-control':true,'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}"></o-date-picker>
                                    <ODataLookup v-if="attribute.InputType == 'ODataLookupCheckBoxes'" :bind="sel => setCheckBoxValues(sel, attribute.Field, attribute.Columns[0].field)" 
                                                            :itemLoaded="lkpItem => checkBoxesIsSelected(lkpItem, props.dataObject.current[attribute.Field], attribute.Columns[0].field)" :disabled="disabledDO || attribute.Disabled" 
                                                            :value="getCheckboxesJsonValue(props.dataObject.current[attribute.Field])" class="form-control" :data-object="attribute.DataObject" :columns="attribute.Columns" 
                                                            :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" 
                                                            :class="{'border border-danger':attribute.Required  && !props.dataObject.current[attribute.Field]}"/>
                                    <div class="d-flex" v-if="attribute.InputType == 'input2x'">
                                        <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control w-25" :disabled="disabledDO || attribute.Disabled">
                                        <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field2]" class="form-control w-75 ms-1" :disabled="disabledDO || attribute.Disabled">
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        </div>
    </div>
</template>

<script setup>
    import * as DataObjects from 'arena.vue.components.AttributesDBObjects.ts';
    //import $t from 'o365.modules.$t.ts'
    import { ref, computed } from 'vue';
    import { getOrCreateDataObject, getDataObjectById } from 'o365.vue.ts'
    import OPersonsLookup from 'o365.vue.components.PersonsLookup.vue'

    const props = defineProps({
        dataObject: Object,
        showAttributes: { default: true },
        customClass: { type: String, required: false, default:'col-lg-3' },
        lazySave: { default: true },
        collapsed: { default: false }
    })
    const requiredFields = ref("");
    const hiddenFields = ref("");
    const searchForAttributes = ref("");
    const isViewerLoading = ref(true);

    const local_dsClassificationCode = getOrCreateDataObject(DataObjects.local_dsClassificationCode),
    local_dsReviewClasses = getOrCreateDataObject(DataObjects.local_dsReviewClasses),
    local_dsTopics = getOrCreateDataObject(DataObjects.local_dsTopics),
    local_dsProjectCodes = getOrCreateDataObject(DataObjects.local_dsProjectCodes),
    local_dsDisciplines = getOrCreateDataObject(DataObjects.local_dsDisciplines),
    local_dsProjectPhases = getOrCreateDataObject(DataObjects.local_dsProjectPhases),
    local_dsMainAreas = getOrCreateDataObject(DataObjects.local_dsMainAreas),
    local_dsPersonsLkp = getOrCreateDataObject(DataObjects.local_dsPersonsLkp),
    local_dsOrgUnitsLkp = getOrCreateDataObject(DataObjects.local_dsOrgUnitsLkp),
    local_dsSubProjects = getOrCreateDataObject(DataObjects.local_dsSubProjects),
    local_dsMetaLookup =  getOrCreateDataObject(DataObjects.local_dsMetaLookup),
    local_dsMetaFields =  getOrCreateDataObject(DataObjects.local_dsMetaFields),
    local_dsCodesLookup =  getOrCreateDataObject(DataObjects.local_dsCodesLookup),
    local_dsCodeTypes =  getOrCreateDataObject(DataObjects.local_dsCodeTypes),
    local_dsCompanies =  getOrCreateDataObject(DataObjects.local_dsCompanies),
    local_dsPlannedChangeLkp =  getOrCreateDataObject(DataObjects.local_dsPlannedChangeLkp),
    local_dsDistReqTypes =  getOrCreateDataObject(DataObjects.local_dsDistReqTypes),
    local_dsDocumentPackages =  getOrCreateDataObject(DataObjects.local_dsDocumentPackages),
    local_dsExtProStatuses =  getOrCreateDataObject(DataObjects.local_dsExtProStatuses),
    local_dsExtProcesses =  getOrCreateDataObject(DataObjects.local_dsExtProcesses),
    local_dsVoidReasons =  getOrCreateDataObject(DataObjects.local_dsVoidReasons),
    local_dsRetentionCodes =  getOrCreateDataObject(DataObjects.local_dsRetentionCodes),
    local_dsFreeTextLkp =  getOrCreateDataObject({
        id: 'local_dsFreeTextLkp',
        viewName: 'aviw_Arena_FreeTextLookup',
        appId: props.dataObject.appId,
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        disableAutoLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Domain_ID",
            masterField: "Domain_ID",
            operator: "equals"
        }],
        fields:
            [{name: "FreeText", type: "string" }]
    }),
    local_dsErrors = getOrCreateDataObject({
        id: 'local_dsErrors',
        viewName: 'atbv_Arena_DocumentsErrors',
        appId: props.dataObject.appId,
        maxRecords: -1,
        whereClause: "",
        loadRecents: true,
        distinctRows: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [{name: "Error", type: "string"},
            {name: "FieldName", type: "string" }]
    }),

    local_dsPersonsWithAccess = getOrCreateDataObject({
        id: 'local_dsPersonsWithAccess',
        viewName: 'aviw_Arena_PersonsWithAccess',
        appId: props.dataObject.appId,
        maxRecords: -1,
        whereClause: "",
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"},
                {name: "Document_ID", type: "string"},
                {name: "MobileNo", type: "string"},
                {name: "Email", type: "string"},
                {name: "Represents", type: "string"}
            ]
    }),
    local_dsWBS = getOrCreateDataObject({
        id: 'local_dsWBS',
        viewName: 'aviw_Complete_WBSLookup',
        appId: props.dataObject.appId,
        maxRecords: -1,
        whereClause: "",
        loadRecents: true,
        distinctRows: true,
        fields:
            [{name: "ID", type: "number"},
            {name: "Name", type: "string" },
            {name: "Title", type: "string" },
            {name: "Parent", type: "string" },
            {name: "NameAndTitle", type: "string" }],
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "OrgUnit_ID",
            masterField: "CostProject_ID",
            operator: "equals"
        }],
    })

    // function selectCheckBoxValues(pSel, pColumn, pDO){
        // let temp = '';
        // if(pSel){
            // pSel.map((item) => {
                // temp += item.Name + ', ';
            // })
        // }else{
            // getDataObjectById(pDO).data.forEach(row => {
                // row.isSelected = false;
            // });
        // }
        // props.dataObject.current[pColumn] = temp.slice(0, -2);
        // props.dataObject.save();
    // }

    // function selectInvolvedContractsbeforeOpen() {
        // if(!props.dataObject.current.InvolvedContracts)
            // return;

        // const testArr = props.dataObject.current.InvolvedContracts.split(', ');
        // local_dsOrgUnitsLkp.data.forEach(row => {
            // if (testArr.indexOf(row.Name) !== -1) {

                // row.isSelected = true;

            // }else{
                // row.isSelected = false;
            // }

        // });
    // }
    // function selectsubProjectsbeforeOpen() {
        // if(!props.dataObject.current.SubProjects)
            // return;

        // const testArr = props.dataObject.current.SubProjects.split(', ');
        // local_dsSubProjects.data.forEach(row => {

            // if (testArr.indexOf(row.Name) !== -1) {

                // row.isSelected = true;

            // }else{
                // row.isSelected = false;
            // }

        // });
    // }

    function getRequiredFields(){
        requiredFields.value = {}
        local_dsErrors.data.map((row)=>{
            if(!props.dataObject.current[row.FieldName]){
                requiredFields.value[row.FieldName] = true;
                requiredFields.value[row.FieldName+'Error'] = row.Error;
            }
        })
    }
    props.dataObject.on('AfterSave',(pFieldsUpdated) => {
        const updateAttributes = computed(() => {
            if(pFieldsUpdated.values.hasOwnProperty('DocumentType_ID') || pFieldsUpdated.values.hasOwnProperty('OrgUnit_ID'))
                return true;
            else
                return false;
        });

        hiddenFields.value = props.dataObject.current.HiddenFields.split(',').map(element => element.trim());
        local_dsErrors.load().then(()=>{
            if(updateAttributes.value){
                getRequiredFields();
                setDefaultAttributes();
                sortAttributes();
            }
        });
    })
    props.dataObject.on('DataLoaded', function(){
        hiddenFields.value = props.dataObject.current.HiddenFields.split(',').map(element => element.trim());
    })
    local_dsErrors.on('DataLoaded', () => {
        getRequiredFields();
    })
    
    // local_dsOrgUnitsLkp.on('DataLoaded', function(){
        // selectInvolvedContractsbeforeOpen();
    // })
    // local_dsSubProjects.on('DataLoaded', function(){
        // selectsubProjectsbeforeOpen();
    // })

    const attributes = ref([]);
    const setDefaultAttributes = () => {
        hiddenFields.value = props.dataObject.current.HiddenFields.split(',').map(element => element.trim());
        attributes.value = [
            {
                Label: $t('Document Group'),
                Field: 'DocumentGroup',
                InputType: 'input',
                Disabled: true,
                Group:"Common"
            },
            {
                Label: $t('Contractor Document No'),
                Field: "ContractorDocumentNo",
                InputType: 'input',
                Group:"Common"
            },
            {
                Label: $t('Client Document Number'),
                Field: "ClientDocumentNo",
                InputType: 'input',
                Group:"Common"
            },
            {
                Label: $t('Contractor Rev'),
                Field: "ContractorRev",
                InputType: 'input',
                Group:"Common"
            },
            {
                Label: $t('Client Rev'),
                Field: "ClientRev",
                InputType: 'input',
                Group:"Common"
            },
            {
                Label: $t('Responsible'),
                Field: "Responsible",
                InputType: 'ODataLookup',
                DataObject: local_dsPersonsWithAccess,
                Bind: (sel) => {props.dataObject.current.Responsible_ID = sel.ID, props.dataObject.current.Responsible = sel.Name;},
                Columns: [
                    {field:'ID', headerName:'ID', width:100},
                    {field:'Name', headerName:'Name', width:200},
                    {field:'MobileNo', headerName:'MobileNo', width:100},
                    {field:'Email', headerName:'Email', width:200},
                    {field:'Represents', headerName:'Represents', width:200}
                ],
                Group:"Common"
            },
            {
                Label: $t('Delivered'),
                Field: 'Delivered',
                InputType: 'o-date-picker',
                Disabled: true,
                Group:"Common"
            },
            {
                Label: $t('Classification Code'),
                Field: "ClassificationNameAndTitle",
                RequiredField: "ClassificationCode",
                InputType: 'ODataLookup',
                DataObject: local_dsClassificationCode,
                Bind: (sel) => {props.dataObject.current.ClassificationCode_ID = sel.ID, props.dataObject.current.ClassificationNameAndTitle = sel.NameAndTitle},
                Columns: [
                    {field:'ID', headerName:'ID', width:100},
                    {field:'Name', headerName:'Name', width:200},
                    {field:'Title', headerName:'Title', width:200}
                ],
                WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%' AND Closed IS NULL",
                Group:"Common"
            },
            {
                Label: $t('Topic'),
                Field: "Topic",
                RequiredField: "Topic",
                InputType: 'ODataLookup',
                DataObject: local_dsTopics,
                Bind: (sel) => {props.dataObject.current.Topic_ID = sel.ID, props.dataObject.current.Topic = sel.NameAndDescription;},
                Columns: [
                    {field:'NameAndDescription', headerName:'Name And Description', width:300}
                ],
                WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
                Group:"Common"
            },
            {
                Label: $t('Project Code'),
                Field: "ProjectCode",
                RequiredField: "ProjectCode",
                InputType: 'ODataLookup',
                DataObject: local_dsProjectCodes,
                Bind: (sel) => {props.dataObject.current.ProjectCode_ID = sel.ID, props.dataObject.current.ProjectCode = sel.Name;},
                Columns: [
                    {field:'ID', headerName:'ID', width:100},
                    {field:'Name', headerName:'Name', width:200},
                    {field:'OrgUnit', headerName:'OrgUnit', width:200},
                ],
                WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
                Group:"Common"
            },
            {
                Label: $t('Discipline'),
                Field: "DisciplineNameAndTitle",
                RequiredField: "Discipline",
                InputType: 'ODataLookup',
                DataObject: local_dsDisciplines,
                Bind: (sel) => {props.dataObject.current.Discipline_ID = sel.ID, props.dataObject.current.DisciplineNameAndTitle = sel.NameAndTitle;},
                Columns: [
                    {field:'ID', headerName:'ID', width:100},
                    {field:'Name', headerName:'Name', width:200},
                    {field:'Title', headerName:'Title', width:200},
                ],
                WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%' AND Closed IS NULL",
                Group:"Common"
            },
            {
                Label: $t('Project Phase'),
                Field: "ProjectPhase",
                RequiredField: "ProjectPhase",
                InputType: 'ODataLookup',
                DataObject: local_dsProjectPhases,
                Bind: (sel) => {props.dataObject.current.ProjectPhase_ID = sel.ID, props.dataObject.current.ProjectPhase = sel.Name;},
                Columns: [
                    {field:'ID', headerName:'ID', width:100},
                    {field:'Name', headerName:'Name', width:200},
                    {field:'Title', headerName:'Title', width:200},
                ],
                WhereClause: "DescendantOrgUnit_ID = " + props.dataObject.current.OrgUnit_ID,
                Group:"Common"
            },
            {
                Label: $t('Main Area'),
                Field: "MainArea",
                RequiredField: "MainArea",
                InputType: 'ODataLookup',
                DataObject: local_dsMainAreas,
                Bind: (sel) => {props.dataObject.current.MainArea_ID = sel.ID, props.dataObject.current.MainArea = sel.Name;},
                Columns: [
                    {field:'Name', headerName:'Name', width:200},
                    {field:'Title', headerName:'Title', width:200},
                ],
                WhereClause: "(DocumentGroup_ID = " + props.dataObject.current.DocumentGroup_ID + " OR DocumentGroup_ID IS NULL) AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]",
                Group:"Common"
            },
            {
                Label: $t('Sub Projects'),
                Field: "SubProjects",
                RequiredField: "SubProjects",
                InputType: 'ODataLookupCheckBoxes',
                DataObject: local_dsSubProjects,
                //Bind: (sel) => selectCheckBoxValues(sel,'SubProjects', 'dsSubProjects'),
                Columns: [
                    {field:'Name', headerName:'Name', width:400}
                ],
                WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
                MultiSelect: true,
                //BindClear: () => selectCheckBoxValues(null,'SubProjects', 'dsSubProjects'),
                Group:"Common"
            },
            {
                Label: $t('Involved Contracts'),
                Field: "InvolvedContracts",
                RequiredField: "InvolvedContracts",
                InputType: 'ODataLookupCheckBoxes',
                DataObject: local_dsOrgUnitsLkp,
                //Bind: sel => selectCheckBoxValues(sel,'InvolvedContracts', 'local_dsOrgUnitsLkp'),
                Columns: [
                    {field:'NameAndTitle', headerName:'NameAndTitle', width:200},
                    {field:'UnitType', headerName:'UnitType', width:100},
                    {field:'Parent', headerName:'Parent', width:200},
                ],
                WhereClause: "[IdPath] LIKE '" + props.dataObject.current.OrgUnitIdPath + "%'",
                MultiSelect: true,
                //BindClear: () => selectCheckBoxValues(null,'InvolvedContracts', 'local_dsOrgUnitsLkp'),
                Group:"Common"
            },
            {
                Label: $t('Review Class'),
                Field: "ReviewClass",
                InputType: 'ODataLookup',
                DataObject: local_dsReviewClasses,
                Bind: sel => {props.dataObject.current.ReviewClass_ID = sel.ID, props.dataObject.current.ReviewClass = sel.Name;},
                Columns: [
                    {field:'ID', headerName:'ID', width:100},
                    {field:'Name', headerName:'Name', width:200},
                ],
                Group:"Common"
            },
            {
                Label: $t('Keywords'),
                Field: "Keywords",
                InputType: 'input',
                Group:"Common"
            },
            {
                Label: $t('Progress / Weighting'),
                Field: "Progress",
                Field2: "ProgressWeighting",
                InputType: 'input2x',
                Group:"Planning"
            },
            {
                Label: $t('WBS'),
                Field: "WBSNameAndTitle",
                InputType: 'ODataLookup',
                DataObject: local_dsWBS,
                Bind: sel => {props.dataObject.current.WBS_ID = sel.ID, props.dataObject.current.WBSNameAndTitle = sel.NameAndTitle;},
                Columns: [
                    {field:'Parent', headerName:'Parent', width:200},
                    {field:'Title', headerName:'Title', width:200},
                    {field:'Name', headerName:'Name', width:200}
                ],
                Group:"Other"
            },
            {
                Label: $t('Comments'),
                Field: "Comments",
                InputType: 'OTextArea',
                Group:"Other"
            },
            {
                Label: $t('Next Delivery Date'),
                Field: "NextDeliveryDate",
                InputType: 'o-date-picker',
                Group:"Planning"
            },
            {
                Label: $t('Planned IDC'),
                Field: "PlannedIDC",
                RequiredField: "PlannedIDC",
                InputType: 'o-date-picker',
                Title: $t('Planned Interdisciplinary Check'),
                Group:"Planning"
            },
            {
                Label: $t('Planned IFR'),
                Field: "PlannedIFR",
                RequiredField: "PlannedIFR",
                InputType: 'o-date-picker',
                Title: $t('Planned Issued for Review'),
                Group:"Planning"
            },
            {
                Label: $t('Planned IFD'),
                Field: "PlannedIFD",
                RequiredField: "PlannedIFD",
                InputType: 'o-date-picker',
                Title: $t('Planned Issued for Design'),
                Group:"Planning"
            },
            {
                Label: $t('Planned IFI/IFU/IFC'),
                Field: "PlannedIFU",
                RequiredField: "PlannedIFU",
                InputType: 'o-date-picker',
                Title: $t('Planned Issued for Use / Construction'),
                Group:"Planning"
            },
            {
                Label: $t('Planned ASB'),
                Field: "PlannedASB",
                RequiredField: "PlannedASB",
                InputType: 'o-date-picker',
                Title: $t('Planned As Built'),
                Group:"Planning"
            },
            {
                Label: $t('Planned Start'),
                Field: "PlannedStart",
                RequiredField: "PlannedStart",
                InputType: 'o-date-picker',
                Group:"Planning"
            },
            {
                Label: $t('Planned Verified'),
                Field: "PlannedVerified",
                RequiredField: "PlannedVerified",
                InputType: 'o-date-picker',
                Group:"Planning"
            },
            {
                Label: $t('Planned Released'),
                Field: "PlannedReleased",
                RequiredField: "PlannedReleased",
                InputType: 'o-date-picker',
                Group:"Planning"
            },
            {
                Label: $t('Planned TPR'),
                Field: "PlannedTPR",
                RequiredField: "PlannedTPR",
                InputType: 'o-date-picker',
                Title: $t('Planned Third Party Review'),
                Group:"Planning"
            },
            {
                Label: $t('Forecast IDC'),
                Field: "ForecastIDC",
                RequiredField: "ForecastIDC",
                InputType: 'o-date-picker',
                Title: $t('Forecast Interdisciplinary Check'),
                Group:"Planning"
            },
            {
                Label: $t('Forecast IFR'),
                Field: "ForecastIFR",
                RequiredField: "ForecastIFR",
                InputType: 'o-date-picker',
                Title: $t('Forecast Issued for Review'),
                Group:"Planning"
            },
            {
                Label: $t('Forecast IFD'),
                Field: "ForecastIFD",
                RequiredField: "ForecastIFD",
                InputType: 'o-date-picker',
                Title: $t('Forecast Issued for Design'),
                Group:"Planning"
            },
            {
                Label: $t('Forecast IFI/IFU/IFC'),
                Field: "ForecastIFU",
                RequiredField: "ForecastIFU",
                InputType: 'o-date-picker',
                Title: $t('Forecast Issued for Use / Construction'),
                Group:"Planning"
            },
            {
                Label: $t('Forecast ASB'),
                Field: "ForecastASB",
                RequiredField: "ForecastASB",
                InputType: 'o-date-picker',
                Title: $t('Forecast As Built'),
                Group:"Planning"
            },
            {
                Label: $t('Forecast Start'),
                Field: "ForecastStart",
                RequiredField: "ForecastStart",
                InputType: 'o-date-picker',
                Group:"Planning"
            },
            {
                Label: $t('Forecast Verified'),
                Field: "ForecastVerified",
                RequiredField: "ForecastVerified",
                InputType: 'o-date-picker',
                Group:"Planning"
            },
            {
                Label: $t('Forecast Released'),
                Field: "ForecastReleased",
                RequiredField: "ForecastReleased",
                InputType: 'o-date-picker',
                Group:"Planning"
            },
            {
                Label: $t('Forecast TPR'),
                Field: "ForecastTPR",
                RequiredField: "ForecastTPR",
                InputType: 'o-date-picker',
                Title: $t('Forecast Third Party Review'),
                Group:"Planning"
            },
            {
                Label: $t('Expiry Tracked By'),
                Field: "ExpiryTrackedBy",
                InputType: 'input',
                Group:"Planning"
            },
            {
                Label: $t('Expiry Date'),
                Field: "ExpiryDate",
                InputType: 'o-date-picker',
                Group:"Planning"
            },
            {
                Label: $t('Person (Applies To)'),
                Field: "Person",
                RequiredField: "Person",
                InputType: 'OPersonsLookup',
                Bind: (sel) => {props.dataObject.current.Person_ID = sel.ID, props.dataObject.current.Person = sel.Name;},
                Group:"Other"
            },
            {
                Label: $t('Retention Code'),
                Field: "RetentionCode",
                InputType: 'ODataLookup',
                DataObject: local_dsRetentionCodes,
                Bind: sel => {props.dataObject.current.RetentionCode = sel.Code;},
                Columns: [
                    {field:'Code', headerName:'Code', width:200},
                    {field:'Description', headerName:'Description', width:200}
                ],
                Group:"Common"
            },
            {
                Label: $t('Archived'),
                Field: "ArchivedDate",
                InputType: "o-date-picker",
                Group:"Common"
            },
            {
                Label: $t('Archived By'),
                Field: 'ArchivedBy',
                InputType: 'input',
                Disabled: true,
                Group:"Common"
            },
            {
                Label: $t('Obsolete'),
                Field: 'Obsolete',
                InputType: "o-date-picker",
                Group:"Common"
            },
            {
                Label: $t('Obsolete Reason'),
                Field: "ObsoleteReason",
                InputType: 'ODataLookup',
                DataObject: local_dsVoidReasons,
                Bind: sel => {props.dataObject.current.ObsoleteReason_ID = sel.VoidReason_ID; props.dataObject.current.ObsoleteReason= sel.Name;},
                Columns: [
                    {field:'Name', headerName:'Name', width:300}
                ],
                Group:"Common"
            },
            {
                Label: $t('External Process'),
                Field: "ExternalProcessName",
                InputType: 'ODataLookup',
                DataObject: local_dsExtProcesses,
                Bind: sel => {props.dataObject.current.ExternalProcessName = sel.Name;},
                Columns: [
                    {field:'Name', headerName:'Name', width:300}
                ],
                Group:"Common"
            },
            {
                Label: $t('External Process Status'),
                Field: "ExternalProcessStatus",
                InputType: 'ODataLookup',
                DataObject: local_dsExtProStatuses,
                Bind: sel => {props.dataObject.current.ExternalProcessStatus = sel.Name;},
                Columns: [
                    {field:'Name', headerName:'Name', width:300}
                ],
                Group:"Common"
            },
            {
                Label: $t('External Process Due Date'),
                Field: 'ExternalProcessDueDate',
                InputType: "o-date-picker",
                Group:"Common"
            },
            {
                Label: $t('External Process Sent Date'),
                Field: 'ExternalProcessSentDate',
                InputType: "o-date-picker",
                Group:"Common"
            },
            {
                Label: $t('External Process Received Date'),
                Field: 'ExternalProcessReceivedDate',
                InputType: "o-date-picker",
                Group:"Common"
            },
            {
                Label: $t('Document Package'),
                Field: "PackageNameAndTitle",
                RequiredField: "DocumentPackage",
                InputType: 'ODataLookup',
                DataObject: local_dsDocumentPackages,
                Bind: sel => {props.dataObject.current.PackageNameAndTitle = sel.Name; props.dataObject.current.Package_ID = sel.ID;},
                Columns: [
                    {field:'Name', headerName:'Name', width:300}
                ],
                Group:"Common"
            },
            {
                Label: $t('Distribution Requirements'),
                Field: "DistributionRequirements",
                InputType: 'ODataLookupCheckBoxes',
                DataObject: local_dsDistReqTypes,
                //Bind: sel => selectCheckBoxValues(sel,'DistributionRequirements', 'local_dsDistReqTypes'),
                Columns: [
                    {field:'Name', headerName:'Name', width:300}
                ],
                MultiSelect: true,
                //BindClear: () => selectCheckBoxValues(null,'DistributionRequirements', 'local_dsDistReqTypes'),
                Group:"Common"
            },
            {
                Label: $t('Company'),
                Field: "Company",
                InputType: 'ODataLookup',
                DataObject: local_dsCompanies,
                Bind: sel => {props.dataObject.current.Company = sel.Name; props.dataObject.current.Company_ID = sel.ID;},
                Columns: [
                    {field:'Name', headerName:'Name', width:200},
                    {field:'Description', headerName:'Description', width:200}
                ],
                Group:"Common"
            },
            {
                Label: $t('Planned Change'),
                Field: "PlannedChange",
                InputType: 'ODataLookup',
                DataObject: local_dsPlannedChangeLkp,
                Bind: sel => {props.dataObject.current.PlannedChange = sel.Name;},
                Columns: [
                    {field:'Name', headerName:'Name', width:200},
                    {field:'Description', headerName:'Description', width:200}
                ],
                Group:"Planning"
            },
            {
                Label: $t('URL'),
                Field: "URL",
                InputType: "input",
                Group:"Other"
            },
            {
                Label: $t('Alternative Title'),
                Field: "AlternativeTitle",
                InputType: "input",
                Group:"Other"
            },
            {
                Label: $t('Date 1'),
                Field: "Date1",
                RequiredField: "Date1",
                InputType: 'o-date-picker',
                Group:"Other"
            },
            {
                Label: $t('Date 2'),
                Field: "Date2",
                RequiredField: "Date2",
                InputType: 'o-date-picker',
                Group:"Other"
            },
            {
                Label: $t('Date 3'),
                Field: "Date3",
                RequiredField: "Date3",
                InputType: 'o-date-picker',
                Group:"Other"
            },
            {
                Label: $t('Free Text 1'),
                Field: "FreeText1",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText1 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=1',
                Group:"Other"
            },
            {
                Label: $t('Free Text 2'),
                Field: "FreeText2",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText2 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=2',
                Group:"Other"
            },
            {
                Label: $t('Free Text 3'),
                Field: "FreeText3",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText3 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=3',
                Group:"Other"
            },
            {
                Label: $t('Free Text 4'),
                Field: "FreeText4",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText4 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=4',
                Group:"Other"
            },
            {
                Label: $t('Free Text 5'),
                Field: "FreeText5",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText5 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=5',
                Group:"Other"
            },
            {
                Label: $t('Free Text 6'),
                Field: "FreeText6",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText6 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=6',
                Group:"Other"
            },
            {
                Label: $t('Free Text 7'),
                Field: "FreeText7",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText7 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=7',
                Group:"Other"
            },
            {
                Label: $t('Free Text 8'),
                Field: "FreeText8",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText8 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=8',
                Group:"Other"
            },
            {
                Label: $t('Free Text 9'),
                Field: "FreeText9",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText9 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=9',
                Group:"Other"
            },
            {
                Label: $t('Free Text 10'),
                Field: "FreeText10",
                InputType: 'ODataLookup',
                DataObject: local_dsFreeTextLkp,
                Bind: sel => {props.dataObject.current.FreeText10 = sel.FreeText;},
                Columns: [
                    {field:'FreeText', headerName:'FreeText', width:400}
                ],
                WhereClause: 'FreeTextNo=10',
                Group:"Other"
            },
        ]
    }
    setDefaultAttributes();

    local_dsCodeTypes.load().then(() => {
        local_dsCodeTypes.data.forEach((row,index) => {
            if(row.Name){
                attributes.value.push(
                    {
                        Label: row.Name,
                        Field: "Code"+(index+1),
                        RequiredField: "Code"+(index+1),
                        InputType: 'ODataLookup',
                        DataObject: local_dsCodesLookup,
                        Bind: sel => {props.dataObject.current['Code'+(index+1)+"_ID"] = sel.ID, props.dataObject.current['Code'+(index+1)] = sel.Name;},
                        Columns: [
                            {field:'ID', headerName:'ID', width:100},
                            {field:'Name', headerName:'Name', width:200},
                            {field:'Title', headerName:'Title', width:200}
                        ],
                        WhereClause: "Seq = 'Code"+(index+1)+"' AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
                        Group:"Other"
                    }
                )
            }
        })

        local_dsMetaFields.load().then(() => {
            for(let i = 0; i < 15; i++){
                if(local_dsMetaFields.data[0]['Meta'+(i+1)]){
                    attributes.value.push(
                    {
                        Label: local_dsMetaFields.data[0]['Meta'+(i+1)],
                        Field: 'Meta'+(i+1),
                        RequiredField: 'Meta'+(i+1),
                        InputType: 'ODataLookup',
                        DataObject: local_dsMetaLookup,
                        Bind: sel => {props.dataObject.current['Meta'+(i+1)] = sel.ID ,props.dataObject.data[0]['Meta'+(i+1)]= sel.Name;},
                        Columns: [
                            {field:'ID', headerName:'ID', width:100},
                            {field:'Name', headerName:'Name', width:200},
                            {field:'Description', headerName:'Description', width:200},
                        ],
                        WhereClause: "MetaType = 'Meta"+(i+1)+"' AND IdPath LIKE '" + props.dataObject.current.OrgUnitIdPath + "'",
                        Group:"Asset"
                    })
                }
            }
            local_dsErrors.load().then(() => {
                sortAttributes();
            });
        })
    })

    function sortAttributes(){
        attributes.value.sort((a,b) => {
            if(requiredFields.value[a.RequiredField ?? a.Field] && !requiredFields.value[b.RequiredField ?? b.Field]){
                a.Required = true;
                return -1;
            }
            if(!requiredFields.value[a.RequiredField ?? a.Field] && requiredFields.value[b.RequiredField ?? b.Field]){
                b.Required = true;
                return 1;
            }
            if(requiredFields.value[a.RequiredField ?? a.Field] &&  requiredFields.value[b.RequiredField ?? b.Field]){
                a.Required = true;
                b.Required = true;
            }
            return 0;
        })

        attributes.value.sort((a,b) => {
            if(!requiredFields.value[a.RequiredField ?? a.Field] && !props.dataObject.current[a.Field] && props.dataObject.current[b.Field]){
                b.IsSet = true;
                return 1;
            }
            if(!requiredFields.value[b.RequiredField ?? b.Field] && !props.dataObject.current[b.Field] && props.dataObject.current[a.Field]){
                a.IsSet = true;
                return -1;
            }
            if(props.dataObject.current[a.Field] && props.dataObject.current[b.Field]){
                a.IsSet = true;
                b.IsSet = true;
            }
            return 0;
        })

        isViewerLoading.value = false;
    }

    const attributeGroups = [
        {Name: "Common",SortOrder:1, Collapsed:false},
        {Name: "Planning",SortOrder:2, Collapsed:true},
        {Name: "Asset",SortOrder:3, Collapsed:true},
        {Name: "Other",SortOrder:4, Collapsed:true},
    ]


    const groupedAttributes = computed(() => {
        // Group the attributes by the 'group' property
        const groups = attributes.value.reduce((groups, attribute) => {
            const groupName = attribute.Group || 'Ungrouped';
            if (!groups[groupName]) {
                groups[groupName] = {
                    name: groupName,
                    attributes: [],
                    sortIndex:attributeGroups.find((group) => group.Name === groupName).SortOrder,
                    collapsed:attributeGroups.find((group) => group.Name === groupName).Collapsed
                };
            }
            groups[groupName].attributes.push(attribute);
            return groups;
        }, {});

        // Convert the groups object to an array
        return Object.values(groups).sort((a, b) => a.sortIndex - b.sortIndex);
    });

    function saveDataObject(){
        if(props.lazySave == false)
            props.dataObject.save();
    }

const disabledDO = computed(() => {
    return (props.dataObject.current.Deleted != null || props.dataObject.current.Obsolete != null || props.dataObject.current.Released)
});

const getCheckboxesJsonValue = (pJson) => {
    if (!pJson){
        return ''
    }
    try {
        return JSON.parse(pJson).join(', ');
    } catch (e) {
        return pJson;
    }  
}

// this has to support values with comma (save in json format)
function checkBoxesIsSelected(plkpItem, pJson, pField){
    if (!pJson){
        return
    }
    try {
        plkpItem.isSelected = JSON.parse(pJson).includes(plkpItem[pField]);
    } catch (e) {
        plkpItem.isSelected = pJson.split(', ').includes(plkpItem[pField]);
    } 
}

// this has to support values with comma (save in json format)
function setCheckBoxValues(pSel, pColumn, pField){
    if(pSel && pSel.length){
        props.dataObject.current[pColumn] = JSON.stringify(pSel.map(item => item[pField]));
    }else{
        props.dataObject.current[pColumn] = null;
    }
    saveDataObject();
}
</script>

<style scoped>
.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}

.overlay__inner{
    display:none;
}

.accordion-item {
    border: var(--bs-accordion-border-width) solid #ddd !important;
}
</style>